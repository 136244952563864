import React, { Component } from "react";
import { Helmet } from 'react-helmet';
// react plugin that prints a given react component
// import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// react component used to create sweet alerts

import { DropdownButton } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
// reactstrap components
import {
    Button,
    Card,
    Container,
    Row
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { getProductionStatus } from "variables/TableData";

const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    alwaysShowAllBtns: false,
    showTotal: false,
    withFirstAndLast: false,
    hidePageListOnlyOnePag: true,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        
                    </select>
                }{" "}
                Per Page。
            </label>
        </div>
    ),
});

// const { SearchBar } = Search;

class ProductionTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingInit: (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status" style={{ width: "5rem", height: "5rem" }}>
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ),
            noData: (
                <div className="d-flex justify-content-center">
                    <label style={{ "fontSize": "20px" }}>No data.</label>
                </div>
            ),
            refresh: (
                <div className="d-flex justify-content-center">
                    <label style={{ "fontSize": "20px" }}>Please refresh the page.</label>
                </div>
            ),
            loading: null,
            alert: null,
            componentRef: React.createRef(),
            InitTable: [{
                "Slab": null,
                "Press-In": null,
                "Press-Out": null,
                "Cure-In": null,
                "Cure-Out": null,
                "Cut-In": null,
                "Cut-Out": null,
                "Polish-In": null,
                "Polish-Out": null
            }],
            dataTable: [{
                "Slab": null,
                "Press-In": null,
                "Press-Out": null,
                "Cure-In": null,
                "Cure-Out": null,
                "Cut-In": null,
                "Cut-Out": null,
                "Polish-In": null,
                "Polish-Out": null
            }],
            slabs: [
                "All"
            ],
            LastID: 0,
            //filterKey: 0,
            selectEPC: "EPC",
        }
        this.loadData = this.loadData.bind(this);
        //this.Change = this.Change.bind(this);
        //this.handleFilter = this.handleFilter.bind(this);
        //this.filterData = this.filterData.bind(this);
    }

    async loadData () {
        this.setState({ loading: this.state.loadingInit })
        //let { EPCs, data } = await getProductionStatus();
        let data = await getProductionStatus();
        if (data !== undefined && data !== 0 && data !== []) {
            this.setState({
                //EPCs: EPCs,
                dataTable: data,
                //selectEPC: "All",
                loading: this.state.loadingInit
            });
        } else {
            this.setState({
                loading: this.state.refresh
            });
        }
    }

    // async Change () {
    //     this.setState({ loading: this.state.noData })
    //     var obj = await this.filterData(this.state.filterKey);
    //     this.setState(state => {
    //         return {
    //             dataTable: obj.dataTable
    //         }
    //     });
    // }

    // async filterData (selectedKey) {
    //     var name = this.state.EPCs[selectedKey];
    //     this.setState({
    //         selectEPC: name,
    //     });
    //     if (name === "All") {
    //         var obj = {};
    //         let value = await getProductionStatus();
    //         if (value !== 0) {
    //             if (value === [])
    //                 obj.dataTable = this.state.InitTable;
    //             else {
    //                 obj.dataTable = value;
    //             }
    //         }
    //         return obj;
    //     }
    //     else {
    //         var obj2 = {};
    //         let value = await getStatusID(name);
    //         if (value !== 0) {
    //             if (value === [])
    //                 obj2.dataTable = this.state.InitTable;
    //             else {
    //                 value.forEach(element => {
    //                     if (element.Nickname !== null && element.Nickname !== 'Null') {
    //                         element.StationName = element.Nickname;
    //                     }
    //                 });
    //                 obj2.dataTable = value;
    //             }
    //         }
    //         else {
    //             obj2.dataTable = this.state.InitTable;
    //         }

    //         return obj2;
    //     }
    // }

    // async handleFilter (selectedKey) {
    //     this.setState({ filterKey: selectedKey });
    //     var obj = await this.filterData(selectedKey);
    //     this.setState({ dataTable: obj.dataTable, loading: this.state.noData });
    //     return false;
    // }

    componentDidMount () {
        this.loadData();
    }

    componentDidUpdate (prevProps, prevState) {
        // if (prevState.LastID !== this.state.LastID) {
        //     this.Change();
        // }
    }

    componentWillUnmount () {
        //clearInterval(this.interval);
    }

    render () {
        // let ItemsTemplate = this.state.EPCs.map((value, index, array) =>
        //     <Dropdown.Item key={value} eventkey={value} onClick={() => this.handleFilter(index)}>{value}</Dropdown.Item>
        // );

        // const copyToClipboardAsTable = (el) => {
        //     var body = document.body,
        //         range,
        //         sel;
        //     if (document.createRange && window.getSelection) {
        //         range = document.createRange();
        //         sel = window.getSelection();
        //         sel.removeAllRanges();
        //         try {
        //             range.selectNodeContents(el);
        //             sel.addRange(range);
        //         } catch (e) {
        //             range.selectNode(el);
        //             sel.addRange(range);
        //         }
        //         document.execCommand("copy");
        //     } else if (body.createTextRange) {
        //         range = body.createTextRange();
        //         range.moveToElementText(el);
        //         range.select();
        //         range.execCommand("Copy");
        //     }
        // this.setState({
        //     alert:
        //         <ReactBSAlert
        //             success
        //             style={{ display: "block", marginTop: "-100px" }}
        //             title="Good job!"
        //             onConfirm={() => this.setState({ alert: null })}
        //             onCancel={() => this.setState({ alert: null })}
        //             confirmBtnBsStyle="default"
        //             btnSize=""
        //         >
        //             複製到剪貼簿！
        //         </ReactBSAlert>
        // });
        //};

        return (
            <>
                {this.state.alert}
                <Helmet>
                    <title>Production Status | Enabl.us</title>
                </Helmet>
                <SimpleHeader name="" />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <ToolkitProvider data={this.state.dataTable} keyField="EPC"
                                    // slab = {
                                    //     line: 0,
                                    //     pressIn: "",
                                    //     pressOut: "",
                                    //     cureIn: "",
                                    //     cureOut: "",
                                    //     cutIn: "",
                                    //     cutOut: "",
                                    //     polishInA: "",
                                    //     polishOutA: "",
                                    //     polishInB: "",
                                    //     polishOutB: "",
                                    //     polishLine: 0,
                                    //     polishRotation: ""
                                    // };

                                    columns={[
                                        // {
                                        //     dataField: "IP",
                                        //     text: "IP",
                                        //     sort: true, //CAN SORT?
                                        //     headerStyle: () => {
                                        //         return { fontSize: "12px", width: "10px" };
                                        //     }
                                        // },
                                        {
                                            dataField: "EPC",
                                            text: "Slab",
                                            sort: true,
                                            headerStyle: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle", width: "80px", height: "50px" };
                                            },
                                            style: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle" };
                                            }
                                        },
                                        {
                                            dataField: "pressIn",
                                            text: "Press\nIn",
                                            sort: true,
                                            headerStyle: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle", width: "130px" };
                                            },
                                            style: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle" };
                                            }
                                        },
                                        {
                                            dataField: "pressOut",
                                            text: "Press\nOut",
                                            sort: true,
                                            headerStyle: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle", width: "130px" };
                                            },
                                            style: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle" };
                                            }
                                        },
                                        {
                                            dataField: "cureIn",
                                            text: "Cure\nIn",
                                            sort: true,
                                            headerStyle: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle", width: "130px" };
                                            },
                                            style: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle" };
                                            }
                                        },
                                        {
                                            dataField: "cureOut",
                                            text: "Cure\nOut",
                                            sort: true,
                                            headerStyle: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle", width: "130px" };
                                            },
                                            style: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle" };
                                            }
                                        },
                                        {
                                            dataField: "cutIn",
                                            text: "Cut\nIn",
                                            sort: true,
                                            headerStyle: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle", width: "130px" };
                                            },
                                            style: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle" };
                                            }
                                        },
                                        {
                                            dataField: "cutOut",
                                            text: "Cut\nOut",
                                            sort: true,
                                            headerStyle: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle", width: "130px" };
                                            },
                                            style: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle" };
                                            }
                                        },
                                        {
                                            dataField: "polishIn",
                                            text: "Polish\nIn",
                                            sort: true,
                                            headerStyle: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle", width: "130px" };
                                            },
                                            style: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle" };
                                            }
                                        },
                                        {
                                            dataField: "polishOut",
                                            text: "Polish\nOut",
                                            sort: true,
                                            headerStyle: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle", width: "130px" };
                                            },
                                            style: () => {
                                                return { fontSize: "12px", whiteSpace: "pre", textAlign: "center", padding: "0", verticalAlign: "middle" };
                                            }
                                        },
                                        // {
                                        //     dataField: "Link",
                                        //     text: "連線品質",
                                        //     sort: true,
                                        //     headerStyle: () => {
                                        //         return { fontSize: "12px", width: "10px" };
                                        //     }
                                        // },
                                        // {
                                        //     dataField: "Signal",
                                        //     text: "信號等級",
                                        //     sort: true,
                                        //     headerStyle: () => {
                                        //         return { fontSize: "12px", width: "10px" };
                                        //     }
                                        // }
                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 table-responsive" style={{ height: "90vh", overflowX: "auto", margin: "auto", width: "96%", padding: "0.2rem" }}>
                                            {/* <Container fluid>
                                                <label>
                                                    <h2 style={{ display: "inline" }}> 連線狀態紀錄 </h2>
                                                    <DropdownButton size="sm" variant="dark" title={"EPC: " + this.state.selectEPC}>
                                                        <div style={{ height: "300px", overflowY: "auto" }}>
                                                            {ItemsTemplate}
                                                        </div>
                                                    </DropdownButton>
                                                </label>
                                            </Container> */}
                                            <BootstrapTable
                                                noDataIndication={() => this.state.loading}
                                                ref={this.state.componentRef}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={true}
                                                id="react-bs-table"
                                                condensed={true}
                                                sort={{ dataField: 'pressIn', order: 'desc' }}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default ProductionTable;