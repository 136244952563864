import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import 'react-edit-text/dist/index.css';
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { getLastSeen, updateStationName } from "variables/TableData";

class StatusCardMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            loadingInit: (
                <Col className="ml--6">
                    <div className="spinner-border" role="status" style={{ width: "2rem", height: "2rem" }}>
                        <span className="sr-only">Loading...</span>
                    </div>
                    <label className="ml-2">Loading ...</label>
                </Col>
            ),
            loading: null,
            NewTable: [{
                "StationName": null,
                "Nickname": null,
                "MAC": null,
                "IP": null,
                "timestamp": null,
                "SSID": null,
                "Access_Point": null,
                "Link": null,
                "Signal": null,
                "Noise": null,
                "Status": "Offline"
            }],
            OriginTable: [{
                "StationName": null,
                "Nickname": null,
                "MAC": null,
                "IP": null,
                "timestamp": null,
                "SSID": null,
                "Access_Point": null,
                "Link": null,
                "Signal": null,
                "Noise": null,
                "Status": "Offline"
            }],
            dataTable: [{
                "StationName": null,
                "Nickname": null,
                "MAC": null,
                "IP": null,
                "timestamp": null,
                "SSID": null,
                "Access_Point": null,
                "Link": null,
                "Signal": null,
                "Noise": null,
                "Status": "Offline"
            }],
            Stations: ["Null"],
            Stations2: ["Null"],
            lastTime: "1970-01-01 00:00:00",
            filterKey: 0,
            status: "Status",
            statusList: ["All", "Online", "Offline"],
            changeValue: "",
            sort: 0
        }
        this.loadData = this.loadData.bind(this);
        this.Change = this.Change.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.filterData = this.filterData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.valueChange = this.valueChange.bind(this);
        this.SortName = this.SortName.bind(this);
        this.SortLastSeen = this.SortLastSeen.bind(this);
        this.SortName2 = this.SortName2.bind(this);
        this.SortLastSeen2 = this.SortLastSeen2.bind(this);
    }

    async loadData () {
        this.setState({ loading: this.state.loadingInit });
        try {
            var data = await getLastSeen();
            if (data !== 0 && data !== []) {
                let stations = Object.values(data).map(item => item.Nickname);
                this.setState({
                    NewTable: data,
                    lastTime: data[0].timestamp,
                    Stations: stations,
                })
            }
            else {
                // this.setState({
                //     alert: (
                //         <ReactBSAlert
                //             info
                //             style={{ display: "block", marginTop: "-100px" }}
                //             title="TimeOut"
                //             onConfirm={() => this.setState({ alert: null })}
                //             onCancel={() => this.setState({ alert: null })}
                //             confirmBtnBsStyle="info"
                //             confirmBtnText="Ok"
                //             btnSize=""
                //         >
                //             取得資料超過時間
                //         </ReactBSAlert>
                //     ),
                //     loading: null
                // });
            }
        } catch (err) {
            console.log(err);
        }
    }

    async Change () {
        var value = this.state.NewTable;

        value.forEach(element => {
            if (element.Nickname !== null && element.Nickname !== 'Null') {
                element.StationName = element.Nickname
            }
            if (element.timestamp !== null) {
                var now = new Date();
                var last = new Date(element.timestamp);
                if (Math.round((now.getTime() - last.getTime()) / 30000) > 1) {
                    element.Status = "Offline"
                }
                else {
                    element.Status = "Online"
                }
            }
            else {
                element.timestamp = "Null"
                element.Status = "Offline"
            }
            if (element.StationName === null) {
                element.StationName = "Null"
            }
            if (element.Nickname === null) {
                element.Nickname = "Null"
            }
        });

        var obj = this.filterData(value, this.state.filterKey);
        const nameList = Object.values(obj.dataTable).map(item => item.Nickname);
        this.setState(state => {
            return {
                OriginTable: value,
                dataTable: obj.dataTable,
                status: obj.selectStatus,
                Stations2: nameList
            }
        });
        console.log(this.state.sort)
        if (this.state.sort === 1) {
            this.SortName();
        }
        else if (this.state.sort === 2) {
            this.SortLastSeen();
        }
        this.setState({ loading: null });
    }

    filterData (value, selectedKey) {
        var status = this.state.statusList;

        var data = [];
        if (status[selectedKey] !== "All") {
            for (let i = 0; i < value.length; i++) {
                if (value[i].Status === status[selectedKey]) {
                    data.push(value[i]);
                }
            }
        }
        else {
            data = value;
        }

        var obj = {};
        obj.dataTable = data;
        obj.selectStatus = status[selectedKey];

        return obj;
    }

    handleFilter (selectedKey) {
        var obj = this.filterData(this.state.OriginTable, selectedKey);
        const nameList = Object.values(obj.dataTable).map(item => item.Nickname);
        this.setState(state => {
            return {
                filterKey: selectedKey,
                dataTable: obj.dataTable,
                status: obj.selectStatus,
                Stations2: nameList
            }
        });
        if (this.state.sort === 1) {
            this.SortName2(obj.dataTable);
        }
        else if (this.state.sort === 2) {
            this.SortLastSeen2(obj.dataTable);
        }
    }

    handleChange (e) {
        if (e.key === "Enter") {
            var index = parseInt(e.target.name);
            var value = e.target.value;
            var table = this.state.dataTable;
            if (value !== table[index].Nickname && value !== "") {
                updateStationName(table[index].MAC, value);
                table[index].Nickname = value;
                const nameList = Object.values(table).map(item => item.Nickname);
                this.setState({ dataTable: table, Stations2: nameList });
            }
            else if (value === "") {
                e.target.value = table[index].Nickname;
            }
        }
    }

    valueChange (e) {
        var index = parseInt(e.target.name);
        var value = e.target.value;
        var Stations = this.state.Stations2;
        Stations[index] = value;
        this.setState({ Stations2: Stations });
    }

    SortName () {
        var table = this.state.dataTable;
        table.sort(function (a, b) {
            var nameA = a.StationName.toUpperCase();
            var nameB = b.StationName.toUpperCase();
            if (nameA === "NULL")
                nameA = "____"
            if (nameB === "NULL")
                nameB = "____"
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

        const nameList = Object.values(table).map(item => item.Nickname);
        this.setState(state => {
            return {
                dataTable: table,
                Stations2: nameList,
                sort: 1
            }
        });
    }

    SortName2 (table) {
        table.sort(function (a, b) {
            var nameA = a.StationName.toUpperCase();
            var nameB = b.StationName.toUpperCase();
            if (nameA === "NULL")
                nameA = "____"
            if (nameB === "NULL")
                nameB = "____"
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

        const nameList = Object.values(table).map(item => item.Nickname);
        this.setState(state => {
            return {
                dataTable: table,
                Stations2: nameList,
                sort: 1
            }
        });
    }

    SortLastSeen () {
        var table = this.state.dataTable;
        table.sort(function (a, b) {
            var LastSeenA = a.timestamp;
            var LastSeenB = b.timestamp;
            if (LastSeenA === "Null")
                LastSeenA = "1970-01-01 00:00:00"
            if (LastSeenB === "Null")
                LastSeenB = "1970-01-01 00:00:00"
            LastSeenA = new Date(LastSeenA);
            LastSeenB = new Date(LastSeenB);
            if (LastSeenA > LastSeenB) {
                return -1;
            }
            if (LastSeenA < LastSeenB) {
                return 1;
            }

            return 0;
        });

        const nameList = Object.values(table).map(item => item.Nickname);
        this.setState(state => {
            return {
                dataTable: table,
                Stations2: nameList,
                sort: 2
            }
        });
    }

    SortLastSeen2 (table) {
        table.sort(function (a, b) {
            var LastSeenA = a.timestamp;
            var LastSeenB = b.timestamp;
            if (LastSeenA === "Null")
                LastSeenA = "1970-01-01 00:00:00"
            if (LastSeenB === "Null")
                LastSeenB = "1970-01-01 00:00:00"
            LastSeenA = new Date(LastSeenA);
            LastSeenB = new Date(LastSeenB);
            if (LastSeenA > LastSeenB) {
                return -1;
            }
            if (LastSeenA < LastSeenB) {
                return 1;
            }

            return 0;
        });

        const nameList = Object.values(table).map(item => item.Nickname);
        this.setState(state => {
            return {
                dataTable: table,
                Stations2: nameList,
                sort: 2
            }
        });
    }

    componentDidMount () {
        this.loadData();
        this.interval = setInterval(this.loadData, 30000);
    }

    componentDidUpdate (prevProps, prevState) {
        if (prevState.lastTime !== this.state.lastTime || prevState.Stations !== this.state.Stations) {
            this.Change();
        }
    }

    componentWillUnmount () {
        clearInterval(this.interval);
    }

    render () {
        let lastSeen = (status) => {
            if (status === "Offline") {
                return <>
                    <span className="text-pink mr-1">●</span>
                </>
            }
            else {
                return <>
                    <span className="text-green mr-1">●</span>
                </>
            }
        }

        let CardsTemplate = this.state.dataTable.map((value, index, array) =>
            <Col key={index} lg="2" md="3" xs="6" sm="4">
                <Card>
                    <CardBody>
                        <h4 className="mb-0">
                            {lastSeen(value.Status)}
                            {value.StationName}
                        </h4>
                        <p className="text-xs text-muted mb-0">Last Seen : </p>
                        <p className="text-sm text-muted mb-0">{value.timestamp}</p>
                    </CardBody>
                </Card>
            </Col>
        );

        let ItemsTemplate = this.state.statusList.map((value, index, array) =>
            <DropdownItem key={value} onClick={() => this.handleFilter(index)}>{value}</DropdownItem>
        );

        return (
            <>
                {this.state.alert}
                <Helmet>
                    <title>Connection Status ｜ Enabl.us</title>
                </Helmet>
                <SimpleHeader name=""></SimpleHeader>
                <Container className="mt--6" fluid>
                    <Row className="ml--4 mb-4">
                        <Col>
                            <Container fluid>
                                <Button
                                    className="buttons-asc buttons-html5"
                                    color="default"
                                    size="sm"
                                    id="asc-tooltip"
                                    onClick={this.SortName}
                                >
                                    <span>Sort: Station</span>
                                </Button>
                                <Button
                                    className="buttons-desc buttons-html5"
                                    color="default"
                                    size="sm"
                                    id="desc-tooltip"
                                    onClick={this.SortLastSeen}
                                >
                                    <span>Sort :Time</span>
                                </Button>
                                <UncontrolledButtonDropdown size="sm">
                                    <DropdownToggle caret color="dark" >
                                        {this.state.status}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {ItemsTemplate}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Container>
                        </Col>
                        {this.state.loading}
                    </Row>
                    <Row>
                        {CardsTemplate}
                    </Row>
                </Container>
            </>
        );
    }
}

export default StatusCardMain;