import React, { Component } from "react";
import { Helmet } from 'react-helmet';
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import { DropdownButton } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
// reactstrap components
import {
    Button,
    Card,
    Container,
    Row
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { getRecordIDs, getRecordAll, getRecordID } from "variables/TableData";

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: false,
    showTotal: false,
    withFirstAndLast: false,
    hidePageListOnlyOnePag: true,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                顯示{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                筆。
            </label>
        </div>
    ),
});

const { SearchBar } = Search;

class RecordTableMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingInit: (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status" style={{ width: "5rem", height: "5rem" }}>
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ),
            noData: (
                <div className="d-flex justify-content-center">
                    <label style={{ "fontSize": "20px" }}>目前無資料！請從上方選單選取一個工作台。</label>
                </div>
            ),
            loading: null,
            alert: null,
            componentRef: React.createRef(),
            InitTable: [{
                "Id": null,
                "EPC": null,
                "StationName": null,
                "time_in": null,
                "time_out": null,
                "time": null,
                "RSSI_avg": null,
                "RSSI_min": null,
                "RSSI_max": null,
                "loss_avg": null,
                "noise_avg": null,
                "rf_avg": null,
                "temp_avg": null,
                "count": null,
                "missing_count": null
            }],
            dataTable: [{
                "Id": null,
                "EPC": null,
                "StationName": null,
                "time_in": null,
                "time_out": null,
                "time": null,
                "RSSI_avg": null,
                "RSSI_min": null,
                "RSSI_max": null,
                "loss_avg": null,
                "noise_avg": null,
                "rf_avg": null,
                "temp_avg": null,
                "count": null,
                "missing_count": null
            }],
            Stations: [
                "All"
            ],
            LastID: 0,
            filterKey: 0,
            selectStation: "工作台",
        }
        this.loadData = this.loadData.bind(this);
        this.Change = this.Change.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.filterData = this.filterData.bind(this);
    }

    async loadData () {
        this.setState({ loading: this.state.loadingInit })
        let stations = await getRecordIDs();
        if (stations === 0 || stations === [])
            stations = this.state.Stations;
        let data = await getRecordAll(10000);
        this.setState({
            LastID: 1,
            Stations: stations,
            dataTable: data, selectStation: "All",
            loading: this.state.loadingInit
        });
    }

    async Change () {
        this.setState({ loading: this.state.noData })
        var obj = await this.filterData(this.state.filterKey);
        obj.dataTable.forEach(element => {
            if (element.Nickname !== null && element.Nickname !== 'Null' && element.StationName !== 'All') {
                element.StationName = element.Nickname;
            }
        });
        this.setState(state => {
            return {
                dataTable: obj.dataTable
            }
        });
    }

    async filterData (selectedKey) {
        var stations = this.state.Stations;
        var name = stations[selectedKey];
        this.setState({
            dataTable: [], selectStation: name,
            loading: this.state.loadingInit
        });
        if (name === "All") {
            var obj = {};
            let value = await getRecordAll(10000);
            if (value !== 0) {
                if (value === [])
                    obj.dataTable = this.state.InitTable;
                else {
                    value.forEach(element => {
                        if (element.Nickname !== null && element.Nickname !== 'Null') {
                            element.StationName = element.Nickname;
                        }
                    });
                    obj.dataTable = value;
                }
            }
            else {
                // this.setState({
                //     alert: (
                //         <ReactBSAlert
                //             info
                //             style={{ display: "block", marginTop: "-100px" }}
                //             title="TimeOut"
                //             onConfirm={() => this.setState({ alert: null })}
                //             onCancel={() => this.setState({ alert: null })}
                //             confirmBtnBsStyle="info"
                //             confirmBtnText="Ok"
                //             btnSize=""
                //         >
                //             取得資料超過時間
                //         </ReactBSAlert>
                //     )
                // });
                obj.dataTable = this.state.InitTable;
            }
            return obj;
        }
        else {
            var obj2 = {};
            let value = await getRecordID(name);
            if (value !== 0) {
                if (value === [])
                    obj2.dataTable = this.state.InitTable;
                else {
                    value.forEach(element => {
                        if (element.Nickname !== null && element.Nickname !== 'Null') {
                            element.StationName = element.Nickname;
                        }
                    });
                    obj2.dataTable = value;
                }
            }
            else {
                // this.setState({
                //     alert: (
                //         <ReactBSAlert
                //             info
                //             style={{ display: "block", marginTop: "-100px" }}
                //             title="TimeOut"
                //             onConfirm={() => this.setState({ alert: null })}
                //             onCancel={() => this.setState({ alert: null })}
                //             confirmBtnBsStyle="info"
                //             confirmBtnText="Ok"
                //             btnSize=""
                //         >
                //             取得資料超過時間
                //         </ReactBSAlert>
                //     )
                // });
                obj2.dataTable = this.state.InitTable;
            }

            return obj2;
        }
    }

    async handleFilter (selectedKey) {
        this.setState({ filterKey: selectedKey });
        var obj = await this.filterData(selectedKey);
        this.setState({ dataTable: obj.dataTable, loading: this.state.noData });
        let stations = await getRecordIDs();
        stations = stations === 0 ? this.state.Stations : stations;
        this.setState(state => {
            return {
                Stations: stations
            }
        });
    }

    componentDidMount () {
        this.loadData();
    }

    componentDidUpdate (prevProps, prevState) {
        // if (prevState.LastID !== this.state.LastID) {
        //   this.Change();
        // }
    }

    componentWillUnmount () {
        clearInterval(this.interval);
    }

    render () {
        let ItemsTemplate = this.state.Stations.map((value, index, array) =>
            <Dropdown.Item key={value} eventkey={value} onClick={() => this.handleFilter(index)}>{value}</Dropdown.Item>
        );

        const copyToClipboardAsTable = (el) => {
            var body = document.body,
                range,
                sel;
            if (document.createRange && window.getSelection) {
                range = document.createRange();
                sel = window.getSelection();
                sel.removeAllRanges();
                try {
                    range.selectNodeContents(el);
                    sel.addRange(range);
                } catch (e) {
                    range.selectNode(el);
                    sel.addRange(range);
                }
                document.execCommand("copy");
            } else if (body.createTextRange) {
                range = body.createTextRange();
                range.moveToElementText(el);
                range.select();
                range.execCommand("Copy");
            }
            // this.setState({
            //     alert:
            //         <ReactBSAlert
            //             success
            //             style={{ display: "block", marginTop: "-100px" }}
            //             title="Good job!"
            //             onConfirm={() => this.setState({ alert: null })}
            //             onCancel={() => this.setState({ alert: null })}
            //             confirmBtnBsStyle="default"
            //             btnSize=""
            //         >
            //             複製到剪貼簿！
            //         </ReactBSAlert>
            // });
        };

        return (
            <>
                {this.state.alert}
                <Helmet>
                    <title>讀取紀錄｜TY RFID</title>
                </Helmet>
                <SimpleHeader name="" />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col" overflow="hidden">
                            <Card>
                                <ToolkitProvider data={this.state.dataTable} keyField="Id"
                                    columns={[
                                        {
                                            dataField: "EPC",
                                            text: "ID",
                                            sort: true, //CAN SORT?
                                            headerStyle: () => {
                                                return { fontSize: "14px", width: "10px" };
                                            }
                                        },
                                        {
                                            dataField: "Nickname",
                                            text: "工作台",
                                            sort: true,
                                            headerStyle: () => {
                                                return { fontSize: "14px", width: "15px" };
                                            }
                                        },
                                        {
                                            dataField: "time_in",
                                            text: "掃描時間",
                                            sort: true,
                                            headerStyle: () => {
                                                return { fontSize: "14px", width: "20px" };
                                            }
                                        },
                                        {
                                            dataField: "time",
                                            text: "持續時間",
                                            sort: true,
                                            headerStyle: () => {
                                                return { fontSize: "14px", width: "20px" };
                                            }
                                        },
                                        {
                                            dataField: "RSSI_avg",
                                            text: "平均 RSSI",
                                            sort: true,
                                            headerStyle: () => {
                                                return { fontSize: "14px", width: "10px" };
                                            }
                                        }
                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 table-responsive" style={{ "minHeight": "400px" }}>
                                            <Container fluid>
                                                <label>
                                                    <h2 style={{ display: "inline" }}> 讀取紀錄 </h2>
                                                    <DropdownButton size="sm" variant="dark" title={"Selected Station: " + this.state.selectStation}>
                                                        <div style={{ height: "300px", overflowY: "auto" }}>
                                                            {ItemsTemplate}
                                                        </div>
                                                    </DropdownButton>
                                                </label>
                                            </Container>
                                            <BootstrapTable
                                                noDataIndication={() => this.state.loading}
                                                ref={this.state.componentRef}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={true}
                                                id="react-bs-table"
                                                rowStyle={{ fontSize: '14px' }}
                                                condensed={true}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default RecordTableMain;