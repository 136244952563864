import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
    Card,
    CardBody,
    Container,
    Row,
    Col,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import * as d3 from "d3"
// import axios from "axios";
import { getLastSeen, createNodeLinkData } from "variables/TableData";

class ForceTreeMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            width: window.innerWidth,
            height: window.innerHeight,
            lastWidth: window.innerWidth,
            lastHeight: window.innerHeight,
            NewTable: [{
                "StationName": null,
                "MAC": null,
                "IP": null,
                "timestamp": null,
                "SSID": null,
                "Access_Point": null,
                "Link": null,
                "Signal": null,
                "Noise": null,
                "Status": "Offline"
            }],
            lastTime: "1970-01-01 00:00:00"
        };
        this.loadData = this.loadData.bind(this);
        this.Change = this.Change.bind(this);
        this.createNodeLink = this.createNodeLink.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    async loadData () {
        var data = await getLastSeen();
        if (data !== 0 && data !== []) {
            this.setState({
                NewTable: data,
                lastTime: data[0].timestamp
            })
        }
        else {
            // this.setState({
            //     alert: (
            //         <ReactBSAlert
            //             info
            //             style={{ display: "block", marginTop: "-100px" }}
            //             title="TimeOut"
            //             onConfirm={() => this.setState({ alert: null })}
            //             onCancel={() => this.setState({ alert: null })}
            //             confirmBtnBsStyle="info"
            //             confirmBtnText="Ok"
            //             btnSize=""
            //         >
            //             Get Data is Timeout
            //         </ReactBSAlert>
            //     )
            // });
        }
    }

    createNodeLink (value) {
        var data = createNodeLinkData(value);
        data.links.forEach(element => {
            var isSameSource = (node) => node.id === element.source;
            var isSameTarget = (node) => node.id === element.target;
            element.source = data.nodes.findIndex(isSameSource);
            element.target = data.nodes.findIndex(isSameTarget);
        });
        return data;
    }

    drawTree () {
        var data = this.createNodeLink(this.state.NewTable);
        d3.select("#the_SVG_ID").remove();

        var color = d3.scale.category20();

        var force = d3.layout.force()
            .charge(-150)
            .linkDistance((d) => d.value * 150)
            .size([this.state.width *0.75, this.state.height *0.6])
            .nodes(data.nodes)
            .links(data.links);

        var svg = d3.select(this.refs.mountPoint)
            .append("svg")
            .attr("id", "the_SVG_ID")
            .attr("width", this.state.width *0.75)
            .attr("height", this.state.height*0.6)
            //.attr("preserveAspectRatio", "xMinYMin meet")
            .attr("viewBox", "0 0 " + this.state.width *0.75 + " " + this.state.height*0.6)

        //.attr("viewBox", "0 0 " + width + " " + height)
        //.attr("preserveAspectRatio", "xMinYMin")
        //.append("svg")
        // .attr("id", "the_SVG_ID")
        // .attr("width", width)
        // .attr("height", height)

        var link = svg.selectAll("line")
            .data(data.links)
            .enter()
            .append("line")
            .attr({
                'd': function (d) { return 'M ' + d.source.x + ' ' + d.source.y + ' L ' + d.target.x + ' ' + d.target.y },
                'class': 'edgepath',
                'id': function (d, i) { return 'edgepath' + i; }
            })
            .style("pointer-events", "none")
            .style("stroke-width", 3)
            .style('stroke-opacity', 0.6)
            .style("stroke", (d) => {
                if (d.Status === "Online")
                    return "#b3d6df";
                else if (d.Status === "Offline")
                    return "#e9b9c8";
            })
            .attr("marker-end", "url(#resolved)")//根據箭頭標記的id號標記箭頭

        svg.append("marker")
            .attr("id", "resolved")
            .attr("markerUnits", "userSpaceOnUse")
            .attr("viewBox", "0 -5 10 10")//坐標系的區域
            .attr("refX", 18)//箭頭坐標
            .attr("markerWidth", 10)//標識的大小
            .attr("markerHeight", 10)
            .attr("orient", "auto")//繪制方向，可設定為：auto（自動確認方向）和 角度值
            .attr("stroke-width", 2)//箭頭寬度
            .append("path")
            .attr("d", "M0,-5 L10,0 L0,5")//箭頭的路徑
            .attr('fill', "#c4b8e0");//箭頭顏色

        var node = svg.selectAll("circle")
            .data(data.nodes)
            .enter()
            .append("circle")
            .attr("r", 8)
            .attr("fill", (d) => color(d.group))
            .attr('stroke', "#c4b8e0")
            .attr('stroke-width', 2)
            .call(force.drag);

        node.append("title")
            .text(d => d.id);

        var text = svg.selectAll("text")
            .data(data.nodes)
            .enter()
            .append("text")
            .style("fill", "black")
            .text((d) => d.name);

        force.on('tick', () => {
            link
                .attr('x1', (d) => d.source.x)
                .attr('y1', (d) => d.source.y)
                .attr('x2', (d) => d.target.x)
                .attr('y2', (d) => d.target.y);

            node
                .attr('cx', (d) => d.x)
                .attr('cy', (d) => d.y);

            text
                .attr('x', (d) => d.x + 12)
                .attr('y', (d) => d.y + 6);
        });

        force.start();
    }

    Change () {
        this.drawTree();
        //this.setState({ newData: false });
    };

    componentDidMount () {
        this.loadData();
        //this.interval = setInterval(this.loadData, 30000);
        window.addEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate (prevProps, prevState) {
        if (prevState.lastTime !== this.state.lastTime) {
            this.Change();
        }
    }

    componentWillUnmount () {
        //window.addEventListener("resize", null);
        //clearInterval(this.interval);
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        if(Math.abs(window.innerWidth-this.state.width)>50){
            this.setState({ lastWidth: this.state.width, lastHeight: this.state.height, width: window.innerWidth, height: window.innerHeight});
            this.drawTree();
        }
    };

    render () {

        return (
            <>
                {this.state.alert}
                <Helmet>
                    <title>Connection Path ｜ Enabl.us</title>
                </Helmet>
                {/* <SimpleHeader name="" /> */}
                <Container className="mt--12" fluid></Container>
                <Row className="d-flex justify-content-center">
                    <Col className="sm--12">
                        <Card className="card-frame">
                            <CardBody>
                                <div ref="mountPoint" />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default ForceTreeMain;