import RecordTableMain from "views/RecordTableMain.js";
import StatusTableMain from "views/StatusTableMain.js";
import StatusCardMain from "views/StatusCardMain.js";
import ForceTreeMain from "views/ForceTreeMain.js";
import ProductionTable from "views/ProductionTable.js";

const routesMain = [
    {
        path: "ProductionStatus",
        name: "Slab Production",
        icon: "ni ni-books text-purple",
        component: ProductionTable,
        layout: "/",
    },
    // {
    //     path: "Station",
    //     name: "讀取紀錄",
    //     icon: "ni ni-bullet-list-67 text-blue",
    //     component: RecordTableMain,
    //     layout: "/",
    // },
    {
        path: "Status",
        name: "Online Status",
        icon: "ni ni-ui-04 text-green",
        component: StatusCardMain,
        layout: "/",
    },
    {
        path: "ForceTree",
        name: "Connection Path",
        icon: "ni ni-atom text-red",
        component: ForceTreeMain,
        layout: "/",
    },
    // {
    //     path: "StatusHistory",
    //     name: "連線狀態紀錄",
    //     icon: "ni ni-bullet-list-67 text-grey",
    //     component: StatusTableMain,
    //     layout: "/",
    // }
];

export default routesMain;
