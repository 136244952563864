const moment = require('moment');
//const momentLocale = require('moment/locale/zh-tw');
//moment.locale('zh-tw');


export async function getRecordAll (number) {
    try {
        const res = await fetch("https://a.tiot.tw/api/getRecordAll/" + number);

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        return data;
    } catch (err) {
        console.log(err);
        return 0;
    }
}

export async function getInventoryAll (number) {
    try {
        const res = await fetch("https://a.tiot.tw/api/getInventoryAll/" + number);

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        return data;
    } catch (err) {
        console.log(err);
        return 0;
    }
}

export async function getStatusAll (number) {
    try {
        const res = await fetch("https://a.tiot.tw/api/getStatusAll/" + number);

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        for (let i = 0; i < data.length; i++) {
            if (data[i].SSID.includes("tiot") || data[i].SSID === "") {
                data[i].SSID = "網狀結構"
            }
        }
        return data;
    } catch (err) {
        console.log(err);
        return 0;
    }
}

export async function getRecordID (name) {
    try {
        const res = await fetch("https://a.tiot.tw/api/getRecord/" + name);

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        return data;
    } catch (err) {
        console.log(err);
        return 0;
    }
}

export async function getInventoryID (name) {
    try {
        const res = await fetch("https://a.tiot.tw/api/getInventory/" + name);

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        return data;
    } catch (err) {
        console.log(err);
        return 0;
    }
}

export async function getStatusID (name) {
    try {
        const res = await fetch("https://a.tiot.tw/api/getStatus/" + name);

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        for (let i = 0; i < data.length; i++) {
            if (data[i].SSID.includes("tiot") || data[i].SSID === "") {
                data[i].SSID = "網狀結構"
            }
        }
        return data;
    } catch (err) {
        console.log(err);
        return 0;
    }
}

export async function getRecordIDs () {
    try {
        const res = await fetch("https://a.tiot.tw/api/getRecordIDs");

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        data = data.filter(element => element.includes("R&D") === false && element.includes("Null") === false)
        console.log(JSON.stringify(data))
        return data;
    } catch (err) {
        console.log(err);
        return 0;
    }
}

export async function getInventoryIDs () {
    try {
        const res = await fetch("https://a.tiot.tw/api/getInventoryIDs");

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        return data;
    } catch (err) {
        console.log(err);
        return 0;
    }
}

export async function getStatusIDs () {
    try {
        const res = await fetch("https://a.tiot.tw/api/getStatusIDs");

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        return data;
    } catch (err) {
        console.log(err);
        return 0;
    }
}

export async function getLastSeen () {
    try {
        const res = await fetch("https://a.tiot.tw/api/getLastSeen");

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        data = data.filter(element => element.Nickname.includes("R&D") === false && element.Nickname.includes("Null") === false)
        return data;
    } catch (err) {
        console.log(err);
        return 0;
    }
}

export async function getRecordEntity () {
    try {
        const res = await fetch("https://a.tiot.tw/api/GetRecordEntity");

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        return data;
    } catch (err) {
        console.log(err);
        return 0;
    }
}

export async function getStatusEntity () {
    try {
        const res = await fetch("https://a.tiot.tw/api/GetStatusEntity");

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        return data;
    } catch (err) {
        console.log(err);
        return 0;
    }
}

export async function getLastSeenEntity () {
    try {
        const res = await fetch("https://a.tiot.tw/api/GetLastSeenEntity");

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        return data;
    } catch (err) {
        console.log(err);
        return 0;
    }
}

export async function getAQIEntity () {
    try {
        const res = await fetch("https://a.tiot.tw/api/GetAQIEntity");

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        return data;
    } catch (err) {
        console.log(err);
        return 0;
    }
}

export async function restartEntity (EntityName) {
    if (EntityName === "Last Seen")
        EntityName = "LastSeen";

    try {
        let url = "https://a.tiot.tw/api/restart/" + EntityName;
        const res = await fetch(url);

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }
    } catch (err) {
        console.log(err);
    }
}

export async function getOrchestratorStatus (EntityName) {
    if (EntityName === "Last Seen")
        EntityName = "LastSeen";

    try {
        let url = "https://a.tiot.tw/api/OrchestratorStatus/" + EntityName;
        const res = await fetch(url);

        if (res.status >= 500) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        return data;
    } catch (err) {
        console.log(err);
        return null;
    }
}

export async function updateStationName (target, name) {
    var url = 'https://a.tiot.tw/api/Station';
    var obj = { MAC: target, Nickname: name };
    try {
        await fetch(url, {
            method: "PUT",
            body: JSON.stringify(obj),
            headers: {
                "content-type": "application/x-www-form-urlencoded"
            }
        })
    } catch (err) {
        console.log(err);
    }
}

export function createNodeLinkData (data) {
    var targets = [];
    var sources = [];
    var ids = [];

    var obj = {};
    obj.nodes = [];
    obj.links = [];
    data.forEach(element => {
        let tmp = {};
        tmp.name = element.Nickname;
        tmp.id = element.MAC;
        tmp.group = null;

        if (element.SoftMAC !== null && element.SoftMAC !== "") {
            tmp.id = element.SoftMAC;
        }
        if (element.timestamp !== null && element.timestamp !== "") {
            var now = new Date();
            var last = new Date(element.timestamp);
            if (Math.round((now.getTime() - last.getTime()) / 30000) > 1) {
                tmp.Status = "Offline";
                return;
            }
            else {
                tmp.Status = "Online";
            }
        }
        else {
            tmp.Status = "Offline";
            return;
        }

        if (!ids.includes(tmp.id)) {
            ids.push(tmp.id);
            obj.nodes.push(tmp);
        }
        else {
            var isSame = (node) => node.id === tmp.id;
            let index = obj.nodes.findIndex(isSame);
            if (index !== -1 && index < obj.nodes.length) {
                obj.nodes[index].name = tmp.name;
                obj.nodes[index].group = null;
            }
        }

        if (element.Access_Point !== null && element.Access_Point !== "") { //從哪裡連線
            let tmp2 = {};
            tmp2.target = element.Access_Point.toLowerCase();
            tmp2.source = tmp.id;

            if (element.Signal < 0)
                tmp2.value = (100 - (120 + element.Signal)) / 100 + 0.5;
            else
                tmp2.value = (100 - element.Signal) / 100 + 0.5;
            tmp2.Status = tmp.Status;
            obj.links.push(tmp2);

            if (!targets.includes(tmp2.target)) {
                targets.push(tmp2.target);
            }

            if (!sources.includes(tmp2.source)) {
                sources.push(tmp2.source);
            }

            if (!ids.includes(tmp2.target)) {
                ids.push(tmp2.target);
                let AP = {};
                AP.name = element.SSID;
                AP.id = tmp2.target;
                AP.group = 0;
                AP.Status = tmp2.Status;
                obj.nodes.push(AP);
            }
        }

        if (element.Stations !== null && element.Stations !== "") { //MESH哪些Blue
            let arr = element.Stations.split(", ")
            for (let i = 0; i < arr.length;) {
                let tmp3 = {};
                tmp3.target = tmp.id;
                tmp3.source = arr[i];
                if (parseFloat(arr[i + 1]) < 0)
                    tmp3.value = (100 - (120 + parseInt(arr[i + 1]))) / 100 + 0.5;
                else
                    tmp3.value = (100 - parseInt(arr[i + 1])) / 100 + 0.5;
                i += 2;
                tmp3.Status = tmp.Status;
                obj.links.push(tmp3);

                if (!targets.includes(tmp3.target)) {
                    targets.push(tmp3.target);
                }

                if (!sources.includes(tmp3.source)) {
                    sources.push(tmp3.source);
                }
            }
        }
    });


    // obj.links.forEach(element => { //有哪些target
    //     if (!targets.includes(element.target)) {
    //         targets.push(element.target);
    //     }
    // })


    // obj.links.forEach(element => { //有哪些source
    //     if (!sources.includes(element.source)) {
    //         sources.push(element.source);
    //     }
    // })


    obj.nodes.forEach(element => { //有哪些id
        if (!ids.includes(element.id)) {
            ids.push(element.id);
        }
    })

    // let i = 1;
    // sources.forEach(element => {
    //     if (!ids.includes(element)) {
    //         let tmp = {};
    //         tmp.name = "Mesh_" + i;
    //         tmp.id = element;
    //         tmp.group = null;
    //         obj.nodes.push(tmp);
    //         ids.push(element);
    //         i++;
    //     }
    // })

    // let m = 1;
    // sources.forEach(element => {
    //     if (!ids.includes(element)) {
    //         let tmp = {};
    //         tmp.name = "AccessPoint_" + m;
    //         tmp.id = element;
    //         tmp.group = 0;
    //         obj.nodes.push(tmp);
    //         ids.push(element);
    //         m++;
    //     }
    // })

    let l = 1;
    for (let j = 0; j < targets.length; j++) {
        for (let k = 0; k < obj.links.length; k++) {
            if (obj.links[k].targets === targets[j]) {
                var isSame = (node) => node.id === obj.links[k].source;
                let index = obj.nodes.findIndex(isSame);
                if (index !== -1 && index < obj.nodes.length) {
                    if (obj.nodes[index].group === null)
                        obj.nodes[index].group = l;
                }
            }
        }
        l++;
    }

    for (let j = 0; j < obj.nodes.length; j++) {
        if (obj.nodes[j].group === null) {
            obj.nodes[j].group = l;
        }
    }

    obj.nodes.sort(function (a, b) {
        return a.group - b.group;
    });
    return obj;
}

// let stationFromMac = (mac) => {
//     switch (mac) {
//         case "98:84:e3:e2:28:b4":
//         case "38:d2:69:dc:d8:73":
//             return {
//                 name: "光入",
//                 line: 1
//             }
//         case "38:d2:69:dd:2f:f3":
//         case "38:d2:69:dd:3f:96":
//             return {
//                 name: "光出",
//                 line: 1
//             }
//         case "38:d2:69:d4:0f:7c":
//             return {
//                 name: "切入",
//                 line: 1
//             }
//         case "38:d2:69:dc:c9:fc":
//             return {
//                 name: "切出",
//                 line: 1
//             }
//         case "38:d2:69:da:9f:64":
//             return {
//                 name: "壓入",
//                 line: 1
//             }
//         case "38:d2:69:e0:b2:ed":
//             return {
//                 name: "壓出",
//                 line: 1
//             }
//         case "38:d2:69:da:b4:3a":
//             return {
//                 name: "烘入",
//                 line: 1
//             }
//         case "38:d2:69:e0:3b:3a":
//             return {
//                 name: "烘出",
//                 line: 1
//             }
//         case "38:d2:69:da:fe:64":
//         case "38:d2:69:dd:49:f9":
//             return {
//                 name: "光入",
//                 line: 2
//             }
//         case "38:d2:69:da:de:8a":
//         case "38:d2:69:db:2f:e7":
//             return {
//                 name: "光出",
//                 line: 2
//             }
//         case "98:84:e3:e1:79:0a":
//             return {
//                 name: "切入",
//                 line: 2
//             }
//         case "38:d2:69:da:be:01":
//             return {
//                 name: "切出",
//                 line: 2
//             }
//         case "98:84:e3:e0:f5:93":
//             return {
//                 name: "壓入",
//                 line: 2
//             }
//         case "98:84:e3:e2:25:5b":
//             return {
//                 name: "壓出",
//                 line: 2
//             }
//         case "38:d2:69:da:9d:5e":
//             return {
//                 name: "烘入",
//                 line: 2
//             }
//         case "38:d2:69:dc:d8:4f":
//             return {
//                 name: "烘出",
//                 line: 2
//             }
//         default:
//             return "R&D"
//     }
// }

let newSlabObject = () => {
    return {
        line: 0,
        pressIn: "",
        pressInSignal: Number.MIN_SAFE_INTEGER,
        pressOut: "",
        cureIn: "",
        cureOut: "",
        cutIn: "",
        cutOut: "",
        polishIn: "",
        polishOut: "",
        polishInA: "",
        polishInASignal: Number.MIN_SAFE_INTEGER,
        polishOutA: "",
        polishOutASignal: Number.MIN_SAFE_INTEGER,
        polishInB: "",
        polishInBSignal: Number.MIN_SAFE_INTEGER,
        polishOutB: "",
        polishOutBSignal: Number.MIN_SAFE_INTEGER,
        polishLine: 0,
        polishRotation: ""
    }
}

export async function getProductionStatus () {
    try {
        const res = await fetch("https://a.tiot.tw/api/getSlabs");

        if (res.status >= 500) {
            console.error("Bad response from server");
            return [];
        }

        let data = await res.json();

        let productionData = [];
        let EPCs = ["All"];
        let slab = newSlabObject();
        let EPC = data[0].EPC;
        for (let i = 0; i < data.length; i++) {
            if (data[i].EPC !== EPC) {
                slab.EPC = EPC.substring(EPC.length - 4);
                slab.EPC = slab.EPC.substring(0, 2) + "-" + slab.EPC.substring(2);
                if (slab.polishInASignal > slab.polishInBSignal) {
                    slab.polishRotation = "A";
                    slab.polishIn = slab.polishInA;
                    if (slab.polishOutA !== "") {
                        slab.polishOut = slab.polishOutA;
                    } else {
                        slab.polishOut = slab.polishOutB;
                    }
                } else {
                    slab.polishRotation = "B";
                    slab.polishIn = slab.polishInB;
                    if (slab.polishOutB !== "") {
                        slab.polishOut = slab.polishOutB;
                    } else {
                        slab.polishOut = slab.polishOutA;
                    }
                }
                productionData.push(slab);
                EPCs.push(EPC);
                EPC = data[i].EPC;
                slab = newSlabObject();
            }

            switch (data[i].MAC) {
                case "98:84:e3:e2:28:b4":
                    if (slab.polishInA === "") {
                        slab.polishInA = moment(data[i].timestamp_local).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 2 A]");
                        slab.polishLine = 2;
                        slab.polishInASignal = data[i].RSSI;
                    }
                    break;
                case "38:d2:69:dc:d8:73":
                    if (slab.polishInB === "") {
                        slab.polishInB = moment(data[i].timestamp_local).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 2 B]");
                        slab.polishLine = 2;
                        slab.polishInBSignal = data[i].RSSI;
                    }
                    break;
                case "38:d2:69:dd:2f:f3":
                    slab.polishOutA = moment(data[i].time_out).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 2 A]");
                    slab.polishOutASignal = data[i].RSSI;
                    slab.polishLine = 2;
                    break;
                case "38:d2:69:dd:3f:96":
                    slab.polishOutB = moment(data[i].time_out).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 2 B]");
                    slab.polishOutBSignal = data[i].RSSI;
                    slab.polishLine = 2;
                    break;
                case "38:d2:69:d4:0f:7c":
                    if (slab.line === 1) {
                        slab.cutIn = moment(data[i].time_out).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 1]");
                    }
                    break;
                case "38:d2:69:dc:c9:fc":
                    if (slab.line === 1) {
                        slab.cutOut = moment(data[i].timestamp_local).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 1]");
                    }
                    break;
                case "38:d2:69:da:9f:64":
                    if (data[i].RSSI > slab.pressInSignal) {
                        slab.pressInSignal = data[i].RSSI;
                        slab.pressIn = moment(data[i].timestamp_local).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 1]");
                        slab.line = 1;
                    }
                    break;
                case "38:d2:69:e0:b2:ed":
                    if (slab.line === 1) {
                        slab.pressOut = moment(data[i].time_out).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 1]");
                    }
                    break;
                case "38:d2:69:da:b4:3a":
                    if (slab.line === 1 && slab.cureIn === "") {
                        slab.cureIn = moment(data[i].timestamp_local).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 1]");
                    }
                    break;
                case "38:d2:69:e0:3b:3a":
                    if (slab.line === 1) {
                        slab.cureOut = moment(data[i].time_out).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 1]");
                    }
                    break;
                case "38:d2:69:da:fe:64":
                    if (slab.polishInA === "") {
                        slab.polishInA = moment(data[i].timestamp_local).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 1 A]");
                        slab.polishInASignal = data[i].RSSI;
                        slab.polishLine = 1;
                    }
                    break;
                case "38:d2:69:dd:49:f9":
                    if (slab.polishInB === "") {
                        slab.polishInB = moment(data[i].timestamp_local).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 1 B]");
                        slab.polishInBSignal = data[i].RSSI;
                        slab.polishLine = 1;
                    }
                    break;
                case "38:d2:69:da:de:8a":
                    slab.polishOutA = moment(data[i].time_out).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 1 A]");
                    slab.polishOutASignal = data[i].RSSI;
                    slab.polishLine = 1;
                    break;
                case "38:d2:69:db:2f:e7":
                    slab.polishOutB = moment(data[i].time_out).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 1 B]");
                    slab.polishOutBSignal = data[i].RSSI;
                    slab.polishLine = 1;
                    break;
                case "98:84:e3:e1:79:0a":
                    if (slab.line === 2) {
                        slab.cutIn = moment(data[i].time_out).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 2]");
                    }
                    break;
                case "38:d2:69:da:be:01":
                    if (slab.line === 2) {
                        slab.cutOut = moment(data[i].timestamp_local).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 2]");
                    }
                    break;
                case "98:84:e3:e0:f5:93":
                    if (data[i].RSSI > slab.pressInSignal) {
                        slab.pressInSignal = data[i].RSSI;
                        slab.pressIn = moment(data[i].timestamp_local).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 2]");
                        slab.line = 2;
                    }
                    break;
                case "98:84:e3:e2:25:5b":
                    if (slab.line === 2) {
                        slab.pressOut = moment(data[i].timestamp_local).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 2]");
                    }
                    break;
                case "38:d2:69:da:9d:5e":
                    if (slab.line === 2 && slab.cureIn === "") {
                        slab.cureIn = moment(data[i].timestamp_local).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 2]");
                    }
                    break;
                case "38:d2:69:dc:d8:4f":
                    if (slab.line === 2) {
                        slab.cureOut = moment(data[i].time_out).format("MM/DD/YYYY[\r\n] HH:mm:ss");//[  Line 2]");
                    }
                    break;
                default:
            }
        }
        productionData = productionData.filter(element => element.pressIn !== "" && element.cureIn !== "" && element.polishIn !== "" && element.EPC !== "00-4B" && element.EPC.includes("00-"))
        return productionData;
    } catch (err) {
        console.log(err);
        return 0;
    }
}